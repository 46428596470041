// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  EditBase,
  List,
  regex,
  required,
  SimpleForm,
  useCreate,
  useGetList,
  useListContext,
  usePermissions,
  useRedirect,
  useRefresh,
  useUnique,
  useUpdate,
} from 'react-admin';

import { Grid, Typography } from '@mui/material';
import { CreateBase } from 'ra-core';

import 'react-dual-listbox/lib/react-dual-listbox.css';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar, HazPerms } from '../component/customComponents';
import { MyDualListBox } from '../component/dualListBox';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextInputField } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const headers = [
  {
    key: 'id',
    header: 'ID',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'description',
    header: 'description',
  },
];

const Table = () => {
  const { permissions, isLoading } = usePermissions();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState([]);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const redirect = useRedirect();
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data || isLoading) return null;

  const clone = structuredClone(data);

  clone?.forEach((result, idx) => {
    if (HazPerms(permissions, 'advanced_policies', 'edit')) {
      result.edit = <MyEdit url={`/policy_chain/${result.id}`} key={`edit-${idx}`} />;
      if (!headers.some((e) => e.key === 'edit')) headers.push({ key: 'edit', header: 'Edit' });
    }
  });

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title={'Are you sure you want to delete these Policy Chains?'}
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'policy_chain',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Policy Chain',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        data={clone}
        headers={headers}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', 'policy_chain')}
        bulkAction={confirmBulkDelete}
        title="Policy Chains"
        description={<>Policy chains allow you chain (order) multiple policies together. The policies will be evaluated in the order they are listed.</>}
      />
    </>
  );
};

export const PolicyChainList = () => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return HazPerms(permissions, 'advanced_policies', 'read') ? (
    <>
      <MyBreadcrumbs path="/policy_chain" />
      <List sort={{ field: 'name', order: 'ASC' }} perPage={10} actions={false} pagination={false} hasCreate={true}>
        <Table />
      </List>
    </>
  ) : null;
};

export const PolicyChainEdit = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/policy_chain');
    refresh();
    notificationCtx.add({ msg: 'Changes saved.', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'policy_chain' });
  };

  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/policy_chain" />
      <PolicyChainForm isedit="true" />
    </EditBase>
  );
};

export const PolicyChainForm = (props) => {
  const [update] = useUpdate();
  const [create] = useCreate();
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const redirect = useRedirect();
  const refresh = useRefresh();
  const unique = useUnique();

  const { data: policies, isLoading } = useGetList('policy');
  if (isLoading) {
    return;
  }

  const policy_data = policies.map((p) => {
    return { value: p.id, label: p.name };
  });

  const save = (e) => {
    if (props.isedit) {
      update(
        'policy_chain',
        { id: e.id, data: e },
        {
          onSuccess: () => {
            notificationCtx.add({ msg: 'Policy Chain saved.', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'policy_chain' });
          },
        },
      );
    } else {
      create(
        'policy_chain',
        { data: e },
        {
          onSuccess: () => {
            redirect('/policy_chain');
            refresh();
            notificationCtx.add({ msg: 'Policy Chain created.', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'policy_chain', create: true });
          },
        },
      );
    }
  };

  return (
    <SimpleForm
      disableInvalidFormNotification
      {...props}
      toolbar={<CustomToolbar showDelete={props.isedit ? true : false} redirectPath="/policy_chain" resource="policy_chain" />}
      onSubmit={save}
    >
      <CarbonTextInputField
        label="Name *"
        source="name"
        validate={[
          required(),
          unique({ message: 'Name is not unique' }),
          regex(/^[_0-9-a-zA-Z]+$/, 'Name must consist of letters, numbers, underscores or hyphens'),
        ]}
      />
      <CarbonTextInputField source="description" label="Description" />
      <Typography variant="h6">Policies</Typography>
      <Grid container width="100%" sx={{ p: 1 }}>
        <Grid item xs={6}>
          <Typography variant="h6">Available</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ paddingLeft: '10px' }}>
            Selected
          </Typography>
        </Grid>
      </Grid>
      <Grid container width="100%">
        <Grid item xs={12}>
          <MyDualListBox source="policies" options={policy_data} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const PolicyChainCreate = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/policy_chain');
    refresh();
    notificationCtx.add({ msg: 'Policy Chain created.', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'policy_chain', create: true });
  };

  return (
    <CreateBase {...props} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/policy_chain" />
      <PolicyChainForm {...props} redirect="list" />
    </CreateBase>
  );
};
