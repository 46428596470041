// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';
import { useState } from 'react';

import {
  CreateBase,
  Form,
  List,
  PasswordInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useListContext,
  useRedirect,
  useRefresh,
  useUnique,
} from 'react-admin';

import { MisuseOutline } from '@carbon/icons-react';
import { Button, Modal } from '@carbon/react';

import { Typography } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CarbonTextInputField } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const headers = [
  { key: 'id', header: 'ID' },
  { key: 'name', header: 'Name' },
  { key: 'createdAt', header: 'Date Created' },
];

const Table = () => {
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState([]);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const redirect = useRedirect();
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data) return null;

  data?.forEach((result) => {
    result.createdAt = <Typography variant="body2"> {new Date(result.date_created).toLocaleString()}</Typography>;
  });

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title={'Are you sure you want to delete these Access Tokens?'}
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() => {
          batchDeleteAction({
            resource: 'accesstoken',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Access Token',
          });
        }}
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable data={data} headers={headers} showActions={true} zebra={false} addRow={() => redirect('create', 'accesstoken')} bulkAction={confirmBulkDelete} />
    </>
  );
};

export const AccessTokenList = () => {
  return (
    <>
      <MyBreadcrumbs path={'/accesstoken'} />
      <List actions={false} perPage={5} pagination={false}>
        <Table />
      </List>
    </>
  );
};

const ButtonStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: '0',
  color: 'var(--cds-text-on-color, #ffffff)',
  padding: '0',
  boxSizing: 'border-box',
  border: '0',
  margin: 0,
  fontFamily: 'inherit',
  fontSize: 'var(--cds-body-compact-01-font-size, 0.875rem)',
  verticalAlign: 'baseline',
  fontWeight: 'var(--cds-body-compact-01-font-weight, 400)',
  lineHeight: 'var(--cds-body-compact-01-line-height, 1.28572)',
  letterSpacing: 'var(--cds-body-compact-01-letter-spacing, 0.16px)',
  position: 'relative',
  display: 'inline-flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  cursor: 'pointer',
  inlineSize: 'max-content',
  maxInlineSize: '20rem',
  minBlockSize: 'var(--cds-layout-size-height-local)',
  outline: 'none',
  paddingBlock: 'min((var(--cds-layout-size-height-local) - var(--temp-1lh)) / 2 - 0.0625rem, var(--temp-padding-block-max))',
  paddingInlineEnd: 'calc(var(--cds-layout-density-padding-inline-local) * 3 + 1rem - 0.0625rem)',
  paddingInlineStart: 'calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem)',
  textAlign: 'start',
  textDecoration: 'none',
  transition:
    ' background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9)',
  textTransform: 'initial',
};

export const AccessTokenCreate = () => {
  const unique = useUnique();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [token, setToken] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const handleCloseClick = () => {
    redirect('/accesstoken');
  };

  const onCreateSuccess = (data) => {
    setToken(data['token']);
    setShowDialog(true);
    notificationCtx.add({ msg: 'token created', status: 'success' });
    redirect(false);
  };

  const onCreateError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'accesstoken' });
  };

  const handleCancel = () => {
    redirect('show', '/accesstoken');
    refresh();
  };

  const handleCopy = () => {
    document.getElementById('htoken').select();
    document.execCommand('copy');
    notificationCtx.add({ msg: 'Token copied to clipboard', status: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={'/accesstoken'} />
      <>
        <SimpleForm
          disableInvalidFormNotification
          toolbar={
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <SaveButton
                label="Create New Token"
                mutationOptions={{
                  onSuccess: onCreateSuccess,
                  onError: onCreateError,
                }}
                type="button"
                className="cds--btn cds--btn--primary"
                style={{ ...ButtonStyle }}
                sx={{
                  [`& .MuiSvgIcon-root, & .MuiIcon-root`]: {
                    position: 'absolute',
                    flexShrink: 0,
                    insetBlockStart: 'min((var(--cds-layout-size-height-local) - 1rem) / 2 - 0.0625rem, var(--temp-padding-block-max))',
                    insetInlineEnd: 'var(--cds-layout-density-padding-inline-local)',
                    marginBlockStart: '0.0625rem',
                    marginRight: 0,
                    marginTop: 0.51,
                  },
                }}
              />
              <Button kind="secondary" color="primary" onClick={handleCancel} renderIcon={MisuseOutline}>
                Cancel
              </Button>
            </Toolbar>
          }
        >
          <CarbonTextInputField
            label="Token Name"
            source="name"
            validate={[required('This field is required'), unique({ message: 'Token name is not unique' })]}
          />
        </SimpleForm>
        <Modal
          open={showDialog}
          modalHeading={'One Time Token Dialog'}
          secondaryButtonText="Close"
          primaryButtonText="Copy"
          onRequestClose={handleCloseClick}
          onRequestSubmit={handleCopy}
          preventCloseOnClickOutside
        >
          <Typography variant="h8">Please copy your token. After closing this dialog it will not be presented again.</Typography>
          <Form record={{ token: token }} toolbar={false}>
            <PasswordInput
              style={{ marginTop: '10px' }}
              source="token"
              onClick={(event) => event.target.type === 'text' && event.target.select()}
              sx={{ width: '100%' }}
            ></PasswordInput>

            <TextInput source="token" sx={{ position: 'absolute', left: '-1000px', top: '-1000px' }} id="htoken" />
          </Form>
        </Modal>
      </>
    </CreateBase>
  );
};
