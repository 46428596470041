// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  ArrayInput,
  BooleanInput,
  EditBase,
  List,
  ReferenceInput,
  regex,
  required,
  SimpleForm,
  SimpleFormIterator,
  useCreate,
  useGetList,
  useListContext,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUnique,
  useUpdate,
} from 'react-admin';

import { Checkmark, MisuseOutline } from '@carbon/icons-react';

import { Grid, Typography } from '@mui/material';
import { CreateBase, FormDataConsumer } from 'ra-core';

import 'react-dual-listbox/lib/react-dual-listbox.css';

import { Heading, Section } from '@carbon/react';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar, HazPerms } from '../component/customComponents';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextInputField, MyMultiSelect, MySelect } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const headers = [
  {
    key: 'id',
    header: 'ID',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'description',
    header: 'description',
  },
  {
    key: 'results_logged',
    header: 'Results Logged',
  },
];

const Table = () => {
  const { permissions, isLoading } = usePermissions();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState([]);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const redirect = useRedirect();
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data || isLoading) return null;

  const clone = structuredClone(data);

  clone?.forEach((result, idx) => {
    if (HazPerms(permissions, 'policies', 'edit')) {
      result.edit = <MyEdit url={`/policy/${result.id}`} key={`edit-${idx}`} />;
      if (!headers.some((e) => e.key === 'edit')) headers.push({ key: 'edit', header: 'Edit' });
    }
    result.results_logged = result.logging_config.enabled ? <Checkmark /> : <MisuseOutline />;
  });

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title={'Are you sure you want to delete these Policies?'}
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'policy',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Policy',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        data={clone}
        headers={headers}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', 'policy')}
        bulkAction={confirmBulkDelete}
        title="Polices"
        description={
          <>
            Policies allow you take control of DNS resolution. They are <b>deny by default</b>. In other words, all queries will be blocked unless you have an
            explicit allow-type policy.
            <br />
            <br />
            These policies are executed on every DNS query received by Raptor in the order dictated by the <b>Order</b> field below. For each policy, the rules
            defined are executed, taking the context of the current query and elements of identity into account. (This is called the <b>Session Context</b> and
            is described in more detail in the Help page). The first policy&apos;s rule that sets the variable <code>match</code> to <code>true</code> has its{' '}
            <b>Action</b> setting evaluated and the processing of that query ends.
          </>
        }
      />
    </>
  );
};

export const PolicyAction = () => {
  return (
    <Grid container width="100%" spacing={2} sx={{ p: 2 }}>
      <Grid item xs={8}>
        <Typography variant="h6">Action</Typography>
      </Grid>
      <Grid item xs={8} sx={{ p: 2, border: '1px dashed grey' }}>
        <ReferenceInput source="policy/actions" reference="policy/actions">
          <MySelect label="Action Type" source="action.type" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={8}>
        <FormDataConsumer>
          {({ formData }) => {
            let action = formData['action']['type'];
            if (action === 'ns1_answer') {
              return <CarbonTextInputField label="NS1 Record Name" source="action.record"></CarbonTextInputField>;
            } else if (action === 'forward') {
              return (
                <ArrayInput label="Resolvers" source="action.resolvers">
                  <SimpleFormIterator>
                    <CarbonTextInputField label=""></CarbonTextInputField>
                  </SimpleFormIterator>
                </ArrayInput>
              );
            } else if (action === 'static_answer') {
              return (
                <ArrayInput label="" source="action.hosts">
                  <SimpleFormIterator>
                    <CarbonTextInputField label="Static Answers"></CarbonTextInputField>
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }
          }}
        </FormDataConsumer>
      </Grid>
    </Grid>
  );
};

export const PolicyForm = (props) => {
  const [update] = useUpdate();
  const [create] = useCreate();
  const notificationCtx = React.useContext(NotificationContext);

  const redirect = useRedirect();
  const refresh = useRefresh();
  const unique = useUnique();
  const record = useRecordContext();
  const { handleError } = useHandleError();

  const { data: sinks, isLoading } = useGetList('sinks', { pagination: { page: 1, perPage: 100 }, sort: { field: 'name', order: 'ASC' } });
  const { data: rules, isLoading: rulesLoading } = useGetList('rules', { pagination: { page: 1, perPage: 100 }, sort: { field: 'name', order: 'ASC' } });

  if ((props.isedit && !record) || isLoading || rulesLoading) return null;

  const save = (e) => {
    // strip out empty entries
    if (e?.action?.type === 'forward') {
      e.action.resolvers = e?.action?.resolvers.filter((r) => r !== '');
    } else if (e?.action?.type === 'static_answer') {
      e.action.hosts = e?.action?.hosts.filter((r) => r !== '');
    }

    e.rules = [...e.rules.map((r) => ({ ...r, type: 'rule_ref' }))];

    if (props.isedit) {
      update(
        'policy',
        { id: e.id, data: e },
        {
          onSuccess: () => {
            notificationCtx.add({ msg: 'Policy saved.', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'policy' });
          },
        },
      );
    } else {
      create(
        'policy',
        { data: e },
        {
          onSuccess: () => {
            redirect('/policy');
            refresh();
            notificationCtx.add({ msg: 'Policy created.', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'policy', create: true });
          },
        },
      );
    }
  };

  return (
    <SimpleForm
      disableInvalidFormNotification
      {...props}
      record={record}
      toolbar={
        <CustomToolbar showDelete={props.isedit ? true : false} redirectPath="/policy" resource="policy" perm_resource="policies" perm_actions="delete" />
      }
      onSubmit={save}
    >
      <CarbonTextInputField
        label="Name *"
        source="name"
        validate={[
          required(),
          unique({ message: 'Name is not unique' }),
          regex(/^[_0-9-a-zA-Z]+$/, 'Name must consist of letters, numbers, underscores or hyphens'),
        ]}
      />
      <CarbonTextInputField label="Description" source="description" />
      <BooleanInput source={'logging_config.enabled'} label="Enable logging" />
      <FormDataConsumer>
        {({ formData }) => {
          const isLoggingEnabled = formData['logging_config']?.enabled;
          return isLoggingEnabled ? (
            <>
              <MyMultiSelect
                title="Select Sinks for Logging"
                source="logging_config.sinks"
                choices={sinks?.map((sink) => ({ id: sink.id, text: sink.enabled ? sink.name : `${sink.name} (disabled)` }))}
              />
            </>
          ) : null;
        }}
      </FormDataConsumer>
      <BooleanInput source={'notifications_config.enabled'} label="Enable notifications" />
      <FormDataConsumer>
        {({ formData }) => {
          const isNotificationsEnabled = formData['notifications_config']?.enabled;
          return isNotificationsEnabled ? (
            <>
              <MyMultiSelect
                title="Select Sinks for Notifications"
                source="notifications_config.sinks"
                choices={sinks?.map((sink) => ({ id: sink.id, text: sink.enabled ? sink.name : `${sink.name} (disabled)` }))}
              />
            </>
          ) : null;
        }}
      </FormDataConsumer>
      <PolicyAction fullWidth source="action" required={true} />
      <Grid>
        <Section level={4}>
          <Heading>Rules</Heading>
        </Section>

        <ArrayInput label="" source="rules">
          <SimpleFormIterator sx={{ marginBottom: '10px' }}>
            <MySelect source="rule_id" showEmpty label="" choices={rules?.map((r) => ({ id: r.id, name: r.name }))} />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </SimpleForm>
  );
};

export const PolicyCreate = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/policy');
    refresh();
    notificationCtx.add({ msg: 'Policy created.', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'policy', create: true });
  };

  return (
    <CreateBase {...props} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/policy" />
      <PolicyForm
        {...props}
        redirect="list"
        defaultValues={{
          action: { type: 'deny' },
          name: '',
          description: '',
          logging_config: {
            enabled: false,
            sinks: [], // Initialize as an empty array
          },
        }}
      />
    </CreateBase>
  );
};

export const PolicyEdit = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/policy');
    refresh();
    notificationCtx.add({ msg: 'Changes saved.', status: 'success' });
  };
  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'policy' });
  };
  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/policy" />
      <PolicyForm isedit="true" />
    </EditBase>
  );
};

export const PolicyList = () => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return HazPerms(permissions, 'policies', 'read') ? (
    <>
      <MyBreadcrumbs path="/policy" />
      <List perPage={5} pagination={false} actions={false} sort={{ field: 'name', order: 'ASC' }} hasCreate={true}>
        <Table />
      </List>
    </>
  ) : null;
};
