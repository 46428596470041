// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { BulkDeleteButton, SaveButton, Toolbar, useDelete, usePermissions, useRecordContext, useRedirect, useRefresh } from 'react-admin';

import { MisuseOutline, TrashCan } from '@carbon/icons-react';
import { Button, Link, Modal } from '@carbon/react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { NotificationContext } from '../context/notificationContext';

const ButtonStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: '0',
  color: 'var(--cds-text-on-color, #ffffff)',
  padding: '0',
  boxSizing: 'border-box',
  border: '0',
  margin: 0,
  fontFamily: 'inherit',
  fontSize: 'var(--cds-body-compact-01-font-size, 0.875rem)',
  verticalAlign: 'baseline',
  fontWeight: 'var(--cds-body-compact-01-font-weight, 400)',
  lineHeight: 'var(--cds-body-compact-01-line-height, 1.28572)',
  letterSpacing: 'var(--cds-body-compact-01-letter-spacing, 0.16px)',
  position: 'relative',
  display: 'inline-flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  cursor: 'pointer',
  inlineSize: 'max-content',
  maxInlineSize: '20rem',
  minBlockSize: 'var(--cds-layout-size-height-local)',
  outline: 'none',
  paddingBlock: 'min((var(--cds-layout-size-height-local) - var(--temp-1lh)) / 2 - 0.0625rem, var(--temp-padding-block-max))',
  paddingInlineEnd: 'calc(var(--cds-layout-density-padding-inline-local) * 3 + 1rem - 0.0625rem)',
  paddingInlineStart: 'calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem)',
  textAlign: 'start',
  textDecoration: 'none',
  transition:
    ' background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9)',
  textTransform: 'initial',
};

const MyDeleteButton = ({ resource, redirectPath }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const notificationCtx = React.useContext(NotificationContext);

  const [deleteOne] = useDelete();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    deleteOne(
      resource,
      { id: record && record.id },
      {
        onSuccess: () => {
          refresh();
          notificationCtx.add({ msg: 'Record deleted!', details: '', status: 'success' });
          redirect('list', redirectPath);
        },
        onError: (err) => {
          notificationCtx.add({ msg: 'Failed to delete record.', details: err?.body?.details, status: 'error' });
        },
      },
    );
    setOpen(false);
    // give time for the notification to show
    await new Promise((r) => setTimeout(r, 500));
  };

  return (
    <>
      <Button kind="danger" onClick={handleClick} renderIcon={TrashCan}>
        Delete
      </Button>
      <Modal
        open={open}
        onRequestClose={handleDialogClose}
        danger
        modalHeading={`Delete: ${record && record.id}`}
        modalLabel="Are you sure you want to delete this item?"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onRequestSubmit={handleConfirm}
      />
    </>
  );
};

const CancelButton = ({ redirectPath }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleClick = () => {
    redirect('show', redirectPath);
    refresh();
  };

  return (
    <Button kind="secondary" color="primary" onClick={handleClick} renderIcon={MisuseOutline}>
      Cancel
    </Button>
  );
};

export const CustomToolbar = ({ redirectPath, showDelete = true, resource, perm_resource = false, perm_actions = false }) => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      <SaveButton
        label="Save"
        className="cds--btn cds--btn--primary"
        style={{ ...ButtonStyle }}
        sx={{
          [`& .MuiSvgIcon-root, & .MuiIcon-root`]: {
            position: 'absolute',
            flexShrink: 0,
            insetBlockStart: 'min((var(--cds-layout-size-height-local) - 1rem) / 2 - 0.0625rem, var(--temp-padding-block-max))',
            insetInlineEnd: 'var(--cds-layout-density-padding-inline-local)',
            marginBlockStart: '0.0625rem',
            marginRight: 0,
            marginTop: 0.51,
          },
        }}
      />
      <CancelButton redirectPath={redirectPath} />
      {showDelete && (perm_resource ? HazPerms(permissions, perm_resource, perm_actions) : true) && (
        <MyDeleteButton resource={resource} redirectPath={redirectPath} />
      )}
    </Toolbar>
  );
};

export const BulkActionButton = (props) => (
  <React.Fragment>
    <BulkDeleteButton {...props} mutationMode="pessimistic" />
  </React.Fragment>
);

// TODO: make this an API call
export const HazPerms = (permissions, resource, action) => {
  var canAccess = false;

  // resource should be set, either to something specific like policies
  // or to wildcard '*'
  if (!resource) {
    return canAccess;
  }

  // if resource is defined but not action, then the check is to see if there are
  // any settings set
  if (!action) {
    if (permissions?.filter((p) => p.resource === resource).length) {
      canAccess = true;
    }
  }

  // check for wildcards
  // return true if the resource and action are *
  if (permissions?.filter((p) => p.resource === '*')?.filter((p) => p.actions.includes('*')).length) {
    canAccess = true;
  }

  // check if resource is wildcard and specific action is defined
  else if (permissions?.filter((p) => p.resource === '*')?.filter((p) => p.actions.includes(action)).length) {
    canAccess = true;
  }

  // check if the requested resource has wildcard for actions
  else if (permissions?.filter((p) => p.resource === resource)?.filter((p) => p.actions.includes('*')).length) {
    canAccess = true;
  }

  // check for specific match on resource and action
  else if (permissions?.filter((p) => p.resource === resource)?.filter((p) => p.actions.includes(action)).length) {
    canAccess = true;
  }
  return canAccess;
};

export const CardWithLink = ({ to, title, subtitle }) => {
  return (
    <Card sx={{ maxWidth: '195px' }}>
      <CardContent>
        <Link href={to}>
          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
            {title}
          </Typography>
        </Link>
        <Typography variant="h5" component="div">
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
};
