// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  BooleanInput,
  EditBase,
  List,
  ReferenceInput,
  regex,
  required,
  SimpleForm,
  useCreate,
  useListContext,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUnique,
  useUpdate,
} from 'react-admin';

import { Checkmark, MisuseOutline } from '@carbon/icons-react';

import { CreateBase, FormDataConsumer } from 'ra-core';

import 'react-dual-listbox/lib/react-dual-listbox.css';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar } from '../component/customComponents';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextInputField, MySelect } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const headers = [
  {
    key: 'id',
    header: 'ID',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'description',
    header: 'description',
  },
  {
    key: 'type',
    header: 'Type',
  },
  {
    key: 'enabled',
    header: 'Enabled',
  },
];

const Table = () => {
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState([]);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const redirect = useRedirect();
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data) return null;

  const clone = structuredClone(data);

  clone?.forEach((result, idx) => {
    result.edit = <MyEdit url={`/sinks/${result.id}`} key={`edit-${idx}`} />;
    if (!headers.some((e) => e.key === 'edit')) headers.push({ key: 'edit', header: 'Edit' });
    result.enabled = result.enabled ? <Checkmark /> : <MisuseOutline />;
    result.type = result.properties?.type ? result.properties?.type : '';
  });

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title={'Are you sure you want to delete these Sinks?'}
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'sinks',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Sink',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        data={clone}
        headers={headers}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', 'sinks')}
        bulkAction={confirmBulkDelete}
        title="Sinks"
      />
    </>
  );
};

export const SinkForm = (props) => {
  const record = useRecordContext();
  const [update] = useUpdate();
  const [create] = useCreate();
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const redirect = useRedirect();
  const refresh = useRefresh();
  const unique = useUnique();

  if (props.isedit && !record) return null;

  const save = (e) => {
    if (e.properties.type === 's3') {
      // remove kinesis fields
      delete e.properties.stream_name;
    } else if (e.properties.type === 'kinesis_streams') {
      // remove s3 fields
      delete e.properties.bucket;
    }

    if (props.isedit) {
      update(
        'sinks',
        { id: e.id, data: e },
        {
          onSuccess: () => {
            notificationCtx.add({ msg: 'Sink saved.', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'sinks' });
          },
        },
      );
    } else {
      create(
        'sinks',
        { data: e },
        {
          onSuccess: () => {
            redirect('/sinks');
            refresh();
            notificationCtx.add({ msg: 'Sink created.', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'sink', create: true });
          },
        },
      );
    }
  };

  return (
    <SimpleForm
      disableInvalidFormNotification
      onSubmit={save}
      {...props}
      toolbar={<CustomToolbar showDelete={props.isedit ? true : false} redirectPath="/sinks" resource="sinks" />}
    >
      <CarbonTextInputField
        label="Name *"
        source="name"
        validate={[
          required(),
          unique({ message: 'Name is not unique' }),
          regex(/^[_0-9-a-zA-Z]+$/, 'Name must consist of letters, numbers, underscores or hyphens'),
        ]}
      />

      <CarbonTextInputField label="Description" source="description" />

      <BooleanInput source={'enabled'} label="Enabled?" />

      <ReferenceInput source="sinks/types" reference="sinks/types">
        <div style={{ width: '25%' }}>
          <MySelect label="Sink Types" source="properties.type" />
        </div>
      </ReferenceInput>

      <FormDataConsumer>
        {({ formData }) => {
          let type = formData.properties.type;
          if (type === 's3') {
            return (
              <div style={{ marginTop: '15px', width: '100%' }}>
                <CarbonTextInputField label="Bucket Name *" source="properties.bucket" />
              </div>
            );
          } else if (type === 'kinesis_streams') {
            return (
              <div style={{ marginTop: '15px', width: '100%' }}>
                <CarbonTextInputField label="Stream Name *" source="properties.stream_name" />
              </div>
            );
          } else return null;
        }}
      </FormDataConsumer>
      <CarbonTextInputField label="Region Name *" source="properties.region" />
      <CarbonTextInputField label="Role ARN *" source="properties.role_arn" />
    </SimpleForm>
  );
};

export const SinkCreate = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/sinks');
    refresh();
    notificationCtx.add({ msg: 'Sink created.', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'sinks', create: true });
  };

  return (
    <CreateBase {...props} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/sinks" />
      <SinkForm
        {...props}
        redirect="list"
        defaultValues={{
          name: '',
          description: '',
          enabled: false,
          properties: {
            type: 's3',
            bucket: '',
            region: '',
          },
        }}
      />
    </CreateBase>
  );
};

export const SinkEdit = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/sinks');
    refresh();
    notificationCtx.add({ msg: 'Changes saved.', status: 'success' });
  };
  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'sinks', create: true });
  };

  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/sinks" />
      <SinkForm isedit="true" />
    </EditBase>
  );
};

export const SinkList = () => {
  return (
    <>
      <MyBreadcrumbs path="/sinks" />
      <List perPage={5} pagination={false} actions={false} sort={{ field: 'name', order: 'ASC' }} hasCreate={true}>
        <Table />
      </List>
    </>
  );
};
